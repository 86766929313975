import { get, post } from '../../utils/request'


/**
 * 根据token获取用户信息
 */
export function findUserByToken (params) {
  return get('/getInfo', params)
}

/**
 * 退出用户
 */
export function userLogOut () {
  return post('/logout')
}

/**
 * 提现
 */
export function userCashOut (params) {
  return post('/accountApply/submitAccountApply',params)
}

// 结算
export function getAccount(data){
    return get('/mobile/account',data)
}
// /mobile/account
// BigDecimal payed //已结算
// BigDecimal noPay //未结算