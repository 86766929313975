<template>
    <div class="page-warp gray-page user-page-warp" v-if="userAllInfo">
        <div class="user-info row">
            <div class="head-box">
                <div class="head-img">
                    <img v-if="userAllInfo.avatar" :src="$thumbBaseUrl+userAllInfo.avatar" />
                    <img v-else src="../../assets/images/head.png" />
                </div>
                <div class="user-main">
                    <div class="company-name" v-if="userAllInfo.userName">{{userAllInfo.userName}}</div>
                    <div class="u-mobile" v-if="userAllInfo.phonenumber">{{userAllInfo.phonenumber}}</div>
                </div>
            </div>
            <!--<div class="user-status" v-if="userAllInfo.userStatus" :class="`user-status-u${userAllInfo.userStatus}`">{{uStatus[userAllInfo.userStatus]}}</div>-->
        </div>
        <div class="row user-box" >
            <div class="row model-title" v-if="userAllInfo.businessManagerPhone">
                <span class="t">专属客服经理</span>
            </div>
            <div class="row service-user mt-10" v-if="userAllInfo.businessManagerPhone">
                <div class="ser-user-img">
                    <img v-if="userAllInfo.avatar" :src="$thumbBaseUrl+userAllInfo.avatar" />
                    <img v-else src="../../assets/images/head.png" />
                </div>
                <div class="ser-user-info">
                    <span class="s-u-name">{{userAllInfo.businessManagerName}}</span>
                    <!-- <span class="s-u-des">WANGYIBO</span> -->
                </div>
                <div class="ser-user-link" @click="toCall">电话：{{userAllInfo.businessManagerPhone}}</div>
            </div>
            <div class="row link-box mt-10">
                <div class="row link-item"
                    v-if="(!item.utype || (item.utype && item.utype == userAllInfo.userType))"
                    v-for="(item,index) in modelList1" :key="index"
                    @click="handlTo(item)">{{item.title}}</div>
            </div>
        </div>
        <div class="row logout-btns">
            <div class="lo-btn" @click="logout">退出登录</div>
        </div>
    </div>
</template>

<script>
    import { info } from '@/api/user'
    import { getAccount, userLogOut } from '@/api/mine/mine'
    export default {
        name:'Mine',
        components: {
        },
        data () {
            return {
                loading: false,
                userAllInfo:null,// 用户信息
                loadingTime: 0,
                modelList1:[
                    {
                        title:'通知公告',
                        url:'/message',
                        dataParm:'',
                        icon:'comment-circle-o'
                    },
                    {
                        title:'我的客户',
                        url:'/client',
                        dataParm:'',
                        icon:'user-circle-o',
                        utype:'02',
                    },
                    {
                        title:'我的企业',
                        url:'/customer',
                        dataParm:'',
                        icon:'after-sale',
                        utype:'11',
                    },
                    {
                        title:'佣金结算',
                        url:'/discount',
                        dataParm:'',
                        icon:'gold-coin-o',
                        utype:'02',
                    },
                    {
                        title:'扶持结算',
                        url:'/rebate',
                        dataParm:'',
                        icon:'after-sale',
                        utype:'01',
                    },
                    {
                        title:'账户信息',
                        url:'/account',
                        dataParm:'',
                        icon:'after-sale',
                        utype:'01',
                    },
                    {
                        title:'账户信息',
                        url:'/account',
                        dataParm:'',
                        icon:'after-sale',
                        utype:'02',
                    },
                    {
                        title:'收件地址',
                        url:'/addressList',
                        dataParm:{from:'mine'},
                        icon:'after-sale',
                        utype:'11',
                    },
                    {
                        title:'我的订单',
                        url:'/individualList',
                        dataParm:'',
                        icon:'after-sale',
                        utype:'11',
                    },
                    {
                        title:'开票记录',
                        url:'/invoice',
                        dataParm:'',
                        icon:'after-sale',
                        utype:'11',
                    },
                    {
                        title:'修改密码',
                        url:'/forgetPassword',
                        dataParm:'',
                        icon:'edit'
                    },
                ],
                accountRes:{
                    payed:'',
                    noPay:''
                },
                uStatus:{
                    1:'签约中',
                    2:'签约中',
                    3:'签约中',
                    4:'签约中',
                    5:'已签约',
                    6:'未签约',
                }
            };
        },
        created () {
            this.getUserInfo();
        },
        methods: {
            async logout () {
                this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: this.loadingTime
                });
                let res = await userLogOut();
                if (res.code == 200 || res.code == '200') {
                    this.loadingTime = 1
                    this.$toast.success({
                        message: res.message,
                        forbidClick: true,
                    });
                    sessionStorage.clear();
                    this.$router.replace({ path: '/login' })
                }
                else {
                    this.loadingTime = 1
                    this.$toast.error({
                        message: res.message,
                        forbidClick: true,
                    });
                }
            },
            getUserInfo(){
                // 根据token获取用户信息
                this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                info().then(res => {
                    this.$toast.clear();      
                    if(res.code == 200){
                        this.getAccountRes();
                        this.userAllInfo = res.data;
                    }
                }).catch(err=>{
                    this.$toast.clear();
                })
            },
            toCall () {
                window.location.href = 'tel:'+this.userAllInfo.businessManagerPhone;
            },
            getAccountRes(){
                // 结算信息
                getAccount().then(res=>{
                    if(res.data){
                        this.accountRes = res.data
                    }
                })
            },
            handlTo(row){
                if(row.url){
                    this.$router.push({
                        path:`${row.url}`,
                        query:row.dataParm ? row.dataParm : {}
                    });
                }
            },
        },
    };
</script>
<style lang="less" scoped>
.user-page-warp { padding-bottom:80px; margin-bottom:0px;}
    .user-info {
        width: 100%; height: auto; overflow: hidden; position: relative; z-index: 1;
        box-sizing: border-box;
        z-index: 1;
        min-height:200px;
        background: url('../../assets/images/login/login-bg.png') no-repeat right 10px;
        background-size: 38% auto;
        padding-bottom:50px;
        .head-box {
            width: 100%; height: auto; overflow: hidden;
            padding:80px 15px 20px 80px;
            box-sizing: border-box; position: relative;
            background-color:#F7F8FB;
            &::after {
                content: '';
                position: absolute;
                right: 0px; bottom:0px;
                width: 38%; height: 100%;;
                background: url('../../assets/images/login/login-bg.png') no-repeat right 10px;
                background-size: 100% auto;
            }
        }
        .head-img { 
            width: 60px; height: 60px; overflow: hidden; text-align: center;
            border-radius: 50%;
            box-sizing: border-box; position: absolute;
            left:16px; top: 77px;
            img {
                width: 100%; height: 100%;
            }
        }
        .user-main {
            width: 100%; height: auto; overflow: hidden;
            float: right;
            .company-name {
                font-size: 16px; line-height: 20px; font-weight: 600; letter-spacing: 1px; color:#191919;
                padding-left:2px;
            }
            .u-mobile {
                display: inline-block; width: auto; height: auto; overflow: hidden;
                padding:2px 8px 2px 20px;
                font-size: 13px; line-height: 16px; color: #404040; margin-top:8px;
                background:#E6E7F1 url('../../assets/images/icon/sj-icon@2x.png') no-repeat 6px center;
                background-size: 12px auto;
                border-radius: 10px;
            }
        }
    }
    .user-box { 
        padding:0px 10px; margin-top: -40px;
        position: relative; z-index: 2;
        box-sizing: border-box;
    }
    .service-user {
        position: relative;
        box-sizing: border-box;
        padding:15px 10px 15px 70px; min-height: 80px;
        background: #3E70F4; border-radius: 3px;
        .ser-user-img {
            width: 50px; height: 50px;
            position: absolute; left: 10px; top: 15px;
        }
        .ser-user-info {
            color:#fff;
            text-align:left;
            float: left;
            display: inline-block; width: auto; height: auto;
            span {
                display: block;
            }
            .s-u-name { font-size: 16px; line-height: 50px;}
            .s-u-des { font-size:10px;}
        }
        .ser-user-link {
            font-size: 16px; text-align:right;
            color:#fff; line-height: 50px;
            float: right; width: auto; height: auto;
        }
    }
    .link-box {
        .link-item {
            height: 50px; line-height: 50px;
            box-sizing: border-box;background:#fff;
            border-radius: 3px; margin-top:8px;
            font-size: 16px;
            padding:0px 10px;
        }
    }
    .logout-btns {
        padding:0px 20px; box-sizing: border-box;
        margin-top:30px;
        .lo-btn {
            width: 94%; height: 50px; line-height: 50px; overflow: hidden;
            text-align: center; color:#fff;
            background:#EB5C5C;
            border-radius: 30px; font-size: 16px;
            &:hover {
                background:#E44949;
            }
        }
    }
</style>